$( function() {
	$( '#restart-form-button' ).click( function( event ) {
		event.preventDefault();
		$( '.form-container' ).removeClass( 'hidden' );
		$( '.success-container' ).addClass( 'hidden' );
	} );

	const $contactForm = $( '#contact-form' );

	$contactForm.find( 'input, textarea, button' ).each( function() {
		var $this = $( this );

		function removeValidationMessage() {
			$this.next( '.form-validation-message' ).remove();
		}

		$this.change( removeValidationMessage );
		$this.keyup( removeValidationMessage );
	} );
	$contactForm.submit( function( event ) {
		event.preventDefault();

		var $form = $( this );
		var $submit = $form.find( 'button[type="submit"]' );
		$form.find( '.form-validation-message' ).remove();

		$submit.attr( 'disabled', 'disabled' );
		$submit.parent().addClass( 'flex flex-col' );
		$submit.after( '<span class="form-validation-message form-validation-info-message">' + settings.translation.sendingMail + '</span>' );
		let data = new FormData( this );
		fetch( settings.siteAddress + '/helpers/contact/contact.php', {
			method: 'post',
			body: new URLSearchParams( data ).toString(),
			headers: {
				'Content-type': 'application/x-www-form-urlencoded; charset=utf-8'
			},
			cache: 'no-store',
			credentials: 'include'
		} ).then( function( response ) {
			$form.find( '.form-validation-message' ).remove();
			return response.text()
		} ).then( function( response ) {
			response = JSON.parse( response );

			if ( response.result === 'error' ) {
				console.error( 'Server response', response );
				if ( response.message ) {
					$submit.after( '<span class="form-validation-message form-validation-error-message">' + response.message + '</span>' );
				}
				if ( response.fields ) {
					for ( const [field, message] of Object.entries( response.fields ) ) {
						$contactForm.find( '#' + field ).each( function() {
							var $this = $( this );
							$this.after( '<span class="form-validation-message form-validation-error-message">' + message + '</span>' );
						} );
					}
				}

				return;
			}

			$contactForm.find( 'input,textarea' )
				.not( ':button, :submit, :reset, :hidden' )
				.attr( 'value', '' )
				.val( '' )
				.prop( 'checked', false )
				.prop( 'selected', false );
			var fileUploader = $.fileuploader.getInstance( document.getElementById( 'uploader-your-files' ) );
			fileUploader.reset();


			$( '.form-container' ).addClass( 'hidden' );
			$( '.success-container' ).removeClass( 'hidden' );
			$form.find( '.form-validation-message' ).remove();

		} ).catch( function( error ) {
			console.error( error );
			$submit.after( '<span class="form-validation-message form-validation-error-message">' + settings.translation.genericError + '</span>' );
		} ).finally( function() {
			$submit.removeAttr( 'disabled' );
		} )
		return false;
	} );
} );
